@import '../../node_modules/react-alice-carousel/lib/alice-carousel.css';
@import '../../node_modules/timeone-components/lib/styles.css';

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
p,
button,
span {
  font-family: 'Montserrat', sans-serif;
}
